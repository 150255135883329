import React, { useCallback, useEffect, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import { pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

function Dropzone({submitArticleSearch, multiArticleSearch}) {
  const {getRootProps, getInputProps, open, acceptedFiles} = useDropzone({
    noClick: true,
    noKeyboard: true
  });

  const processedFilesRef = useRef(new Set());

  const getFileContents = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = () => reject(reader.error);
      reader.readAsArrayBuffer(file);
    });
  };

  const getFileData = useCallback(async (file) => {
    const arrayBuffer = await getFileContents(file);
    const loadingTask = pdfjs.getDocument({ data: arrayBuffer });
    const pdf = await loadingTask.promise;
    const pmidRegex = /PMID:\s*(\d+)/g;
    const fullNameRegex = /(.*), (.*) \(\d+\)/g;
    const pmids = [];
    const authorNames = [];
    const filteredLines = [];

    let publicationZone = false;
    for (let i = 1; i <= pdf.numPages; i++) {
      const page = await pdf.getPage(i);
      const textContent = await page.getTextContent();
      const lines = textContent.items.map((item) => item.str).filter(line => line.trim() !== '')
      let fullNameMatch;
      while ((fullNameMatch = fullNameRegex.exec(lines[0])) !== null && authorNames.length === 0) {
        authorNames.push({
          lastName: fullNameMatch[1],
          givenNames: fullNameMatch[2]
        });
      }
      
      // drop the first 7 lines
      lines.splice(0, 7);
      for (let line of lines) {
        if (line === 'Publications') {
          publicationZone = true;
        }
        else if (line.includes('Proficient Languages')) {
          publicationZone = false;
        }
        if (publicationZone) {
          filteredLines.push(line);
        }
      }
    }


    // Join lines containing PMID with their following lines
    const joinedLines = [];
    for (let i = 0; i < filteredLines.length; i++) {
      if (filteredLines[i].includes('PMID')) {
        // Join current line with next line if it exists
        const joinedLine = filteredLines[i] + (filteredLines[i + 1] ? ' ' + filteredLines[i + 1] : '');
        
        joinedLines.push(joinedLine);
        i++; // Skip the next line since we've already included it
      } else {
        joinedLines.push(filteredLines[i]);
      }
    }

    for (let line of joinedLines) {
      let pmidMatch;
      while ((pmidMatch = pmidRegex.exec(line)) !== null) {
        pmids.push(pmidMatch[1]); // Extract only the PMID number
      }
    }

    const author = authorNames[0] || { lastName: '', givenNames: '' };
    return { authorLastName: author.lastName, authorGivenNames: author.givenNames, pmids };
  }, []);

  const processSingleFile = useCallback(async () => {
    try {
      const { authorLastName, authorGivenNames, pmids } = await getFileData(acceptedFiles[0]);
      submitArticleSearch(authorLastName, authorGivenNames, pmids);
    } catch (error) {
      alert('Error processing your files. Please check that this is a valid MyERAS file.');
      console.error('Error processing file:', error);
    }
  }, [acceptedFiles, submitArticleSearch, getFileData]);

  const processMultipleFiles = useCallback(async () => {
    try {
      const fileData = await Promise.all(acceptedFiles.map(getFileData));
      multiArticleSearch(fileData);
    } catch (error) {
      alert('Error processing your files. Please check that this is a valid MyERAS file.');
      console.error('Error processing files:', error);
    }
  }, [acceptedFiles, multiArticleSearch, getFileData]);

  useEffect(() => {
    // Check if we've already processed these files
    const currentFiles = new Set(acceptedFiles.map(f => f.name));
    if (currentFiles.size === processedFilesRef.current.size && 
        [...currentFiles].every(f => processedFilesRef.current.has(f))) {
      return; // Skip if we've already processed these exact files
    }

    if (acceptedFiles.length === 1) {
      processSingleFile();
      processedFilesRef.current = new Set([acceptedFiles[0].name]);
    } else if (acceptedFiles.length > 1) {
      processMultipleFiles();
      processedFilesRef.current = currentFiles;
    }
  }, [acceptedFiles, processSingleFile, processMultipleFiles]);
    
  return (
    <div className="container">
      <div {...getRootProps({className: 'dropzone'})}>
        <input {...getInputProps()} />
        <button type="button" onClick={open}>
          Select MyERAS File(s)
        </button>
        <br></br>
        <label className='dropzone__instructions'>MyERAS application and Curriculum Vitae PDFs supported<br></br>If you select multiple files, we will automatically export all data to a spreadhseet.</label>
      </div>
    </div>
  );
}

export default Dropzone