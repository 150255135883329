import React from 'react';
import './scores.scss';

import { calculatePVU } from '../../util';

function Scores({ pmids, authorPositionMap, impactMap, totalAuthorsMap, hIndex }) {

  const getPVUsFromPMIDs = (pmids, authorPositionMap, impactMap, totalAuthorsMap) => {
    return pmids.map(pmid => {
      const pes = parseInt(window.localStorage.getItem('pes-' + pmid))
      const authorPos = authorPositionMap.get(pmid)
      const impact = impactMap.get(pmid)
      const totalAuthors = totalAuthorsMap.get(pmid)
      return calculatePVU(authorPos, totalAuthors, impact, pes)
    })
  }

  const pvus = getPVUsFromPMIDs(pmids, authorPositionMap, impactMap, totalAuthorsMap)
  const arcs = pvus.filter((p) => p > 1)
  const totalArticles = pvus.length
  // Display cumulative PVU
  let cumulativePVU = pvus.reduce((a, b) => a + b, 0)
  let cumulativeARCS = arcs.reduce((a, b) => a + b, 0)
  let highEffortIndex = (Math.floor((arcs.length * 10000) / totalArticles) / 100)

  return (
    <section>
      <div className="scores__cumulative-pvu" id="cumulativePVU">{`Cumulative Publication Value Unit (cPVU): ${cumulativePVU.toFixed(2)}`}</div>
      <div className="scores__cumulative-arcs" id="cumulativeARCS">{`Cumulative Arms Race Control Score (ARCS): ${cumulativeARCS.toFixed(2)}`}</div>
      <div className="scores__effort-index" id="highEffortActivityIndex">{`High Effort Activity Index (Total ARCS paper count / Total publications): ${highEffortIndex}%`}</div>
      {hIndex !== undefined && <div className="scores__h-index" id="hIndex">{`Author h-index: ${hIndex}`}</div>}
      <div className="scores__total-articles" id="totalArticles">{`Total Articles: ${totalArticles} | Articles with ARCS Score (PVU > 1): ${arcs.length} | Articles without an ARCS Score (PVU ≤ 1): ${totalArticles - arcs.length}`}</div>
    </section>
  )
}

export default Scores;
