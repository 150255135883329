import React, { useRef, useState } from 'react';
import { ARTICLES_ENDPOINT } from '../../util';
import './unlock-modal.scss';

function UnlockModal({ showModal, setUnlock, setUserInfo }) {

  const [errorMessage, setErrorMessage] = useState();
  const [showApplicationYear, setShowApplicationYear] = useState(false);
  let emailInputRef = useRef(null);
  let specialtyRef = useRef(null);
  let institutionInputRef = useRef(null);
  let screenCheckboxRef = useRef(null);
  let applicationYearRef = useRef(null);

  const onSubmit = () => {
    let email = emailInputRef.current.value;

    if (!email.includes('@')) {
      setErrorMessage('Email invalid, please check spelling and try again');
      return;
    }
    if (institutionInputRef.current.value.length < 1 ||
      specialtyRef.current.value.length < 1 ||
      (screenCheckboxRef.current.checked && applicationYearRef.current.value.length < 1)) {
      setErrorMessage('All shown fields are required');
      return;
    }

    let userInfo = {
      email: email,
      institution: institutionInputRef.current.value,
      specialty: specialtyRef.current.value
    }

    setUnlock(true);
    if (screenCheckboxRef.current.checked) {
      userInfo.applicationYear = applicationYearRef.current.value
    }
    setUserInfo(userInfo)
    localStorage.setItem('user-info', JSON.stringify(userInfo))
    submitUserInfo(userInfo)
  }

  const onCheckboxChange = () => {
    if (screenCheckboxRef.current.checked) {
      setShowApplicationYear(true);
    } else {
      setShowApplicationYear(false);
    }
  }

  const onEmailKeyDown = (event) => {
    if (event.key === 'Enter') {
      onSubmit();
    }
  }

  const submitUserInfo = (user) => {
    const endpoint = `${ARTICLES_ENDPOINT}/user/`
    fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(user)
    }).catch(error => {
      console.error('Error submitting user:', error);
    })
  }

  return (
    <div className={`unlock-modal ${!showModal && 'hidden'}`}>
      <label htmlFor="emailInput"><h2 className='unlock-modal__header'>Enter email to gain access (required):</h2></label>
      <input
        className='unlock-modal__email__input'
        ref={emailInputRef}
        type="email"
        id="emailInput"
        placeholder="your-email@here.com"
        defaultValue=''
        onKeyDown={onEmailKeyDown}
      />
      <label htmlFor="institutionInput"><h2 className='unlock-modal__header'>Enter institution name (required):</h2></label>
      <input
        className='unlock-modal__institution__input'
        ref={institutionInputRef}
        type="text"
        id="institutionInput"
        placeholder="Institution"
        defaultValue=''
        onKeyDown={onEmailKeyDown}
      />
      <label htmlFor="specialtyInput"><h2 className='unlock-modal__header'>Enter specialty name (required):</h2></label>
      <input
        className='unlock-modal__specialty__input'
        ref={specialtyRef}
        type="text"
        id="specialtyInput"
        placeholder="Specialty"
        defaultValue=''
      />
      <label htmlFor="screeningCandidate"><h2 className='unlock-modal__header'>Will you be using this tool to screen candidates?</h2></label>
      <input
        className='unlock-modal__screening-candidate__input'
        ref={screenCheckboxRef}
        type="checkbox"
        id="screeningCandidate"
        defaultValue={true}
        onChange={onCheckboxChange}
      />
      {showApplicationYear && (
        <div className='unlock-modal__application-year'>
          <label htmlFor="applicationYear"><h2 className='unlock-modal__header'>Enter application year (required):</h2></label>
          <input
            className='unlock-modal__application-year__input'
            ref={applicationYearRef}
            type="text"
            id="applicationYear"
            placeholder="2024"
            defaultValue=''
            onKeyDown={onEmailKeyDown}
          />
        </div>
      )}

      <button className="unlock-modal__submit" id="unlockSubmit" type='submit' onClick={onSubmit}>
        Submit
      </button>

      <p>By using this tool, you consent to collection of data about your usage.</p>

      {(errorMessage && errorMessage.length > 0) && <h4 className="unlock-modal__error">{errorMessage}</h4>}
    </div>
  )

}
export default UnlockModal;
